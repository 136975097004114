import {Injectable} from "@angular/core";
import {Router} from '@angular/router'
import {Subject} from "rxjs";
import {RealAuthenticationService} from "./real-authentication.service";
import {JwtTokenClaimsConstants} from "../../constants/jwt-token-claims-constants";

const MINUTES_UNITL_AUTO_LOGOUT = 15 // in mins
const CHECK_INTERVAL = 1500 // in ms
const STORE_KEY = 'lastAction';


@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {

  sessionExpired = new Subject<void>();

  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }

  public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router, private authService: RealAuthenticationService) {
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY, Date.now().toString());
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check(): void {
    const now = Date.now();
    const lastActionTime = this.getLastAction();
    const timeoutTime = lastActionTime + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const isTimeout = now > timeoutTime;

    // Check if the JWT token is expired
    const isTokenExpired: boolean = this.isTokenExpired(Number(this.authService.getLocalStorageExp()));

    // Check if more than 1 minute has passed since the timeout period
    const additionalTimeoutCheck = now > (timeoutTime + 1 * 60 * 1000);

    if (isTokenExpired || additionalTimeoutCheck) {
      localStorage.clear();
      localStorage.removeItem(JwtTokenClaimsConstants.TOKEN);
      localStorage.removeItem(JwtTokenClaimsConstants.CURRENT_USER);
      localStorage.removeItem(JwtTokenClaimsConstants.USER_ID);
      localStorage.removeItem(JwtTokenClaimsConstants.AUTHORITIES);
      localStorage.removeItem(JwtTokenClaimsConstants.USER_SID);
      localStorage.removeItem(JwtTokenClaimsConstants.EXP);

      this.clearCookies();
    }

    if (isTimeout) {
      this.sessionExpired.next();
    }
  }


  isTokenExpired(expirationTime: number): boolean {
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime > expirationTime;
  }

  clearCookies() {
    const cookies = document.cookie.split("; ");
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }
}
