import {Component, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from "ngx-bootstrap/modal";
import {AutoLogoutService} from "./core/services/auto-logout.service";
import {Router} from "@angular/router";

const DEFAULT_COUNTDOWN_VALUE = 10; // value in seconds

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('extentSessionModal', {static: false}) extentSessionModal?: ModalDirective;
  countdown: number = DEFAULT_COUNTDOWN_VALUE; // Initialize countdown to 60 seconds

  constructor(private autoLogoutService: AutoLogoutService, private router: Router) {
  }

  ngOnInit() {

    //catch event from AutoLogoutService that shows user inactivity
    this.autoLogoutService.sessionExpired.subscribe(() => {
      if (localStorage.getItem('userId') !== null) {
        if (!this.extentSessionModal.isShown) {
          this.showExtentSessionModal();
        }
      }
    });
  }

  showExtentSessionModal() {
    this.extentSessionModal.show();
    this.startCountdown();
  }

  startCountdown() {
    this.countdown = DEFAULT_COUNTDOWN_VALUE; // Reset countdown
    const interval = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(interval); // Stop the countdown when it reaches 0
        if (this.extentSessionModal.isShown) {
          // log out the user
          this.logOut();
        }
      }
    }, 1000); // Update countdown every second
  }

  logOut() {
    this.extentSessionModal.hide();
    localStorage.clear();
    this.router.navigate(['/auth/login']);
  }
}
