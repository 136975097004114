import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import {SessionService} from "./session.service";
import {Constants} from "../../constants/constants";

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'es', 'de', 'it', 'ru','ro'];

  constructor(public translate: TranslateService, private cookieService: CookieService,private sessionService: SessionService) {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (this.cookieService.check(Constants.COOCKIES_LANG)) {
      browserLang = this.cookieService.get(Constants.COOCKIES_LANG);
    }
    else {
      this.setLanguage('en');
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|es|de|it|ru|ro/) ? browserLang : 'en');
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    this.sessionService.setLanguage(lang);
    this.cookieService.set(Constants.COOCKIES_LANG, lang);
  }

  public getLanguage(){
    return this.cookieService.get(Constants.COOCKIES_LANG);
  }
}
