/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuthRequestDto } from '../models/auth-request-dto';
import { AuthResponseDto } from '../models/auth-response-dto';
import { MessageDto } from '../models/message-dto';
import { UserDto } from '../models/user-dto';
import { UserDtoCreate } from '../models/user-dto-create';
import { UserPasswordDto } from '../models/user-password-dto';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation setPassword
   */
  static readonly SetPasswordPath = '/set-password';

  /**
   * Set password to account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPassword$Response(params: {
    body: UserPasswordDto
  }): Observable<StrictHttpResponse<MessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticationApiImplService.SetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDto>;
      })
    );
  }

  /**
   * Set password to account.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setPassword(params: {
    body: UserPasswordDto
  }): Observable<MessageDto> {

    return this.setPassword$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
    );
  }

  /**
   * Path part for operation registerUser
   */
  static readonly RegisterUserPath = '/register';

  /**
   * Create a new user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser$Response(params: {
    body: UserDtoCreate
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticationApiImplService.RegisterUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * Create a new user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser(params: {
    body: UserDtoCreate
  }): Observable<UserDto> {

    return this.registerUser$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation refreshToken
   */
  static readonly RefreshTokenPath = '/refresh';

  /**
   * Refresh an authentication token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refreshToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshToken$Response(params: {
    lang: string;
  }): Observable<StrictHttpResponse<AuthResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticationApiImplService.RefreshTokenPath, 'post');
    if (params) {
      rb.query('lang', params.lang, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthResponseDto>;
      })
    );
  }

  /**
   * Refresh an authentication token.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `refreshToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  refreshToken(params: {
    lang: string;
  }): Observable<AuthResponseDto> {

    return this.refreshToken$Response(params).pipe(
      map((r: StrictHttpResponse<AuthResponseDto>) => r.body as AuthResponseDto)
    );
  }

  /**
   * Path part for operation createAuthenticationToken
   */
  static readonly CreateAuthenticationTokenPath = '/authenticate';

  /**
   * Authenticate a user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAuthenticationToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAuthenticationToken$Response(params: {
    body: AuthRequestDto
  }): Observable<StrictHttpResponse<AuthResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticationApiImplService.CreateAuthenticationTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthResponseDto>;
      })
    );
  }

  /**
   * Authenticate a user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createAuthenticationToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAuthenticationToken(params: {
    body: AuthRequestDto
  }): Observable<AuthResponseDto> {

    return this.createAuthenticationToken$Response(params).pipe(
      map((r: StrictHttpResponse<AuthResponseDto>) => r.body as AuthResponseDto)
    );
  }

}
