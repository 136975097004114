import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Constants} from "../../constants/constants";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public loggedUserId = new BehaviorSubject(0);
  public language = new BehaviorSubject(Constants.LANGUAGE_EN);

  setLoggedUserId(value: number) {
    this.loggedUserId.next(value);
  }

  setLanguage(value:string){
    this.language.next(value);
  }
}
