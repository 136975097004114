/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EntityStatusUpdateDto } from '../models/entity-status-update-dto';
import { MessageDto } from '../models/message-dto';
import { PageUser } from '../models/page-user';
import { PageUserDto } from '../models/page-user-dto';
import { Pageable } from '../models/pageable';
import { UserDto } from '../models/user-dto';
import { UserDtoCreate } from '../models/user-dto-create';
import { UserDtoSearch } from '../models/user-dto-search';
import { UserDtoUpdate } from '../models/user-dto-update';
import { UserPasswordUpdateDto } from '../models/user-password-update-dto';
import { UserProjectSearchDto } from '../models/user-project-search-dto';

@Injectable({
  providedIn: 'root',
})
export class UserApiImplService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserById
   */
  static readonly GetUserByIdPath = '/api/users/{userId}';

  /**
   * Get a user by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiImplService.GetUserByIdPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * Get a user by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById(params: {
    userId: number;
  }): Observable<UserDto> {

    return this.getUserById$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/api/users/{userId}';

  /**
   * Update a user by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: {
    userId: number;
    body: UserDtoUpdate
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiImplService.UpdateUserPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * Update a user by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: {
    userId: number;
    body: UserDtoUpdate
  }): Observable<UserDto> {

    return this.updateUser$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation deleteUserById
   */
  static readonly DeleteUserByIdPath = '/api/users/{userId}';

  /**
   * Delete a user by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserById$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<MessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiImplService.DeleteUserByIdPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDto>;
      })
    );
  }

  /**
   * Delete a user by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUserById(params: {
    userId: number;
  }): Observable<MessageDto> {

    return this.deleteUserById$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
    );
  }

  /**
   * Path part for operation updateUserEntityStatus
   */
  static readonly UpdateUserEntityStatusPath = '/api/users/update-status';

  /**
   * Update User Entity Status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserEntityStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserEntityStatus$Response(params: {
    body: EntityStatusUpdateDto
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiImplService.UpdateUserEntityStatusPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * Update User Entity Status.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserEntityStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserEntityStatus(params: {
    body: EntityStatusUpdateDto
  }): Observable<UserDto> {

    return this.updateUserEntityStatus$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation updateUserPassword
   */
  static readonly UpdateUserPasswordPath = '/api/users/update-password/{userId}';

  /**
   * Update User password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserPassword$Response(params: {
    userId: number;
    body: UserPasswordUpdateDto
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiImplService.UpdateUserPasswordPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * Update User password.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserPassword(params: {
    userId: number;
    body: UserPasswordUpdateDto
  }): Observable<UserDto> {

    return this.updateUserPassword$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation getPageUser
   */
  static readonly GetPageUserPath = '/api/users';

  /**
   * Get a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPageUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageUser$Response(params: {
    query?: string;
    pageable: Pageable;
  }): Observable<StrictHttpResponse<PageUserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiImplService.GetPageUserPath, 'get');
    if (params) {
      rb.query('query', params.query, {});
      rb.query('pageable', params.pageable, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageUserDto>;
      })
    );
  }

  /**
   * Get a list of users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPageUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPageUser(params: {
    query?: string;
    pageable: Pageable;
  }): Observable<PageUserDto> {

    return this.getPageUser$Response(params).pipe(
      map((r: StrictHttpResponse<PageUserDto>) => r.body as PageUserDto)
    );
  }

  /**
   * Path part for operation createUser
   */
  static readonly CreateUserPath = '/api/users';

  /**
   * Create a new user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: {
    body: UserDtoCreate
  }): Observable<StrictHttpResponse<UserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiImplService.CreateUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDto>;
      })
    );
  }

  /**
   * Create a new user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: {
    body: UserDtoCreate
  }): Observable<UserDto> {

    return this.createUser$Response(params).pipe(
      map((r: StrictHttpResponse<UserDto>) => r.body as UserDto)
    );
  }

  /**
   * Path part for operation getUserByFirstNameLastNameAndProjectId
   */
  static readonly GetUserByFirstNameLastNameAndProjectIdPath = '/api/users/search';

  /**
   * Search and get a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserByFirstNameLastNameAndProjectId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getUserByFirstNameLastNameAndProjectId$Response(params: {
    body: UserProjectSearchDto
  }): Observable<StrictHttpResponse<Array<UserDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiImplService.GetUserByFirstNameLastNameAndProjectIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDto>>;
      })
    );
  }

  /**
   * Search and get a list of users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserByFirstNameLastNameAndProjectId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getUserByFirstNameLastNameAndProjectId(params: {
    body: UserProjectSearchDto
  }): Observable<Array<UserDto>> {

    return this.getUserByFirstNameLastNameAndProjectId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<UserDto>>) => r.body as Array<UserDto>)
    );
  }

  /**
   * Path part for operation getSearchPageUser
   */
  static readonly GetSearchPageUserPath = '/api/users-search';

  /**
   * Search and get a list of users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSearchPageUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSearchPageUser$Response(params: {
    body: UserDtoSearch
  }): Observable<StrictHttpResponse<PageUserDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiImplService.GetSearchPageUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageUserDto>;
      })
    );
  }

  /**
   * Search and get a list of users.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSearchPageUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSearchPageUser(params: {
    body: UserDtoSearch
  }): Observable<PageUserDto> {

    return this.getSearchPageUser$Response(params).pipe(
      map((r: StrictHttpResponse<PageUserDto>) => r.body as PageUserDto)
    );
  }

  /**
   * Path part for operation checkUserUsage
   */
  static readonly CheckUserUsagePath = '/api/users/usage/{userId}';

  /**
   * Check user usage by ID.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkUserUsage()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkUserUsage$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<MessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiImplService.CheckUserUsagePath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDto>;
      })
    );
  }

  /**
   * Check user usage by ID.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkUserUsage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkUserUsage(params: {
    userId: number;
  }): Observable<MessageDto> {

    return this.checkUserUsage$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
    );
  }

  /**
   * Path part for operation queryRsql
   */
  static readonly QueryRsqlPath = '/api/users/query-rsql';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryRsql()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryRsql$Response(params: {
    query: string;
    pageable: Pageable;
  }): Observable<StrictHttpResponse<PageUser>> {

    const rb = new RequestBuilder(this.rootUrl, UserApiImplService.QueryRsqlPath, 'get');
    if (params) {
      rb.query('query', params.query, {});
      rb.query('pageable', params.pageable, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PageUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queryRsql$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryRsql(params: {
    query: string;
    pageable: Pageable;
  }): Observable<PageUser> {

    return this.queryRsql$Response(params).pipe(
      map((r: StrictHttpResponse<PageUser>) => r.body as PageUser)
    );
  }

}
