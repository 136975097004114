<router-outlet></router-outlet>

<!--    Session expiration modal-->
<div bsModal #extentSessionModal="bs-modal" class="modal fade" id="removeItemModal" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content">
      <div class="modal-body px-4 py-5 text-center">
        <button type="button" class="btn-close position-absolute end-0 top-0 m-3"
                (click)="extentSessionModal.hide()"></button>
        <div class="mb-4 mx-auto">
          <p class="font-size-20">{{'sessionExpiration.title'|translate}}</p>
        </div>
        <p class="text-muted mb-4" style="font-size: 50px">{{ countdown }} sec.</p>

        <div class="hstack gap-2 justify-content-center mb-0">
          <button type="button" class="btn btn-primary me-1" id="remove-item"
                  (click)="this.extentSessionModal.hide();">
            {{'sessionExpiration.button.extend'|translate}}
          </button>
          <button type="button" class="btn btn-secondary" (click)="logOut()">
            {{'sessionExpiration.button.logOut'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
