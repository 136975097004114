import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {SessionService} from "./session.service";
import {JwtTokenClaimsConstants} from "../../constants/jwt-token-claims-constants";
import {AuthenticationApiImplService} from "../../api/services/authentication-api-impl.service";
import {UserApiImplService} from "../../api/services/user-api-impl.service";
import {LanguageService} from "./language.service";

@Injectable({
  providedIn: 'root'
})
export class RealAuthenticationService {

  constructor(private router: Router,
              private sessionService: SessionService,
              private authService: AuthenticationApiImplService,
              private userService: UserApiImplService,
              private languageService: LanguageService) {
  }

  login(credentials: { username: string; password: string }): Observable<any> {
    return this.authService.createAuthenticationToken({body: credentials}).pipe(
      tap(user => {
        const userId = this.getUserId(user.token);

        // Handle the response from the real backend
        localStorage.setItem(JwtTokenClaimsConstants.CURRENT_USER, JSON.stringify(user));
        localStorage.setItem(JwtTokenClaimsConstants.TOKEN, user.token);
        localStorage.setItem(JwtTokenClaimsConstants.USER_ID, this.getUserId(user.token) + '');
        localStorage.setItem(JwtTokenClaimsConstants.AUTHORITIES, this.getAuthorities(user.token));
        localStorage.setItem(JwtTokenClaimsConstants.USER_SID, this.getUserSid(user.token));
        localStorage.setItem(JwtTokenClaimsConstants.PROJECT_IDS, this.getProjectIds(user.token));
        localStorage.setItem(JwtTokenClaimsConstants.EXP, this.getExp(user.token));

        this.loadUser(userId);
        this.router.navigate(['/']);
      })
    );
  }


  logout() {
    localStorage.removeItem(JwtTokenClaimsConstants.TOKEN);
    localStorage.removeItem(JwtTokenClaimsConstants.CURRENT_USER);
    localStorage.removeItem(JwtTokenClaimsConstants.USER_ID);
    localStorage.removeItem(JwtTokenClaimsConstants.AUTHORITIES);
    localStorage.removeItem(JwtTokenClaimsConstants.USER_SID);
    localStorage.removeItem(JwtTokenClaimsConstants.EXP);
  }

  refreshToken(lang: string): void {

    this.authService.refreshToken({lang: lang}).subscribe((res) => {
      this.logout();
      const refreshedToken = res.token;
      localStorage.setItem(JwtTokenClaimsConstants.CURRENT_USER, JSON.stringify(refreshedToken));
      localStorage.setItem(JwtTokenClaimsConstants.TOKEN, refreshedToken);
      localStorage.setItem(JwtTokenClaimsConstants.USER_ID, this.getUserId(refreshedToken) + '');
      localStorage.setItem(JwtTokenClaimsConstants.AUTHORITIES, this.getAuthorities(refreshedToken));
      localStorage.setItem(JwtTokenClaimsConstants.USER_SID, this.getUserSid(refreshedToken));
      localStorage.setItem(JwtTokenClaimsConstants.PROJECT_IDS, this.getProjectIds(refreshedToken));
      localStorage.setItem(JwtTokenClaimsConstants.EXP, this.getExp(refreshedToken));
    })
  }

  private getUserId(token: string): number | null {
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.userId || null;
      } catch (error) {
        console.error('Error parsing token payload', error);
      }
    }
    return null;
  }

  private getAuthorities(token: string): string | null {
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.authorities || null;
      } catch (error) {
        console.error('Error parsing token payload', error);
      }
    }
    return null;
  }

  private getUserSid(token: string): string | null {
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.userSid || null;
      } catch (error) {
        console.error('Error parsing token payload', error);
      }
    }
    return null;
  }

  private getProjectIds(token: string): string | null {
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.projectIds || null;
      } catch (error) {
        console.error('Error parsing token payload', error);
      }
    }
    return null;
  }

  private getExp(token: string): string | null {
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        return payload.exp || null;
      } catch (error) {
        console.error('Error parsing token payload', error);
      }
    }
    return null;
  }

  getAuthoritiesList(): Array<string> {
    const authorities = localStorage.getItem('authorities');
    return authorities?.split(',');
  }

  containsRoles(roles: Array<string>): boolean {
    return this.getAuthoritiesList()?.some(value => roles.includes(value));
  }

  getLocalStorageUserId(): number {
    return Number(localStorage.getItem(JwtTokenClaimsConstants.USER_ID));
  }

  getLocalStorageUserSid(): string {
    return localStorage.getItem(JwtTokenClaimsConstants.USER_SID);
  }

  getLocalStorageExp(): string {
    return localStorage.getItem(JwtTokenClaimsConstants.EXP);
  }

  loadUser(userId: number): void {
    this.userService.getUserById({userId: userId}).subscribe(value => {
      const language = value?.settings.find(value1 => value1.setting.settingName === "language").settingValue;
      // this.sessionService.setLanguage(language);
      this.languageService.setLanguage(language);
    });
  }
}
